import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"
import "./markdown.css"
export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Header />
      <SEO title={frontmatter.title} description={frontmatter.description} />

      <main style={{ marginTop: "4.4em" }}>
        <div id="markdown-container">
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            id="markdown-content"
            style={{ textAlign: "center", alignItems: "center" }}
          />
        </div>
        <section id="start-journey">
          <h2>Know you have a problem?</h2>
          <Link
            className="inverted-button"
            target="_blank"
            to="/vicedrop/"
            style={{ color: "rgb(40, 53, 65)", borderColor: "rgb(40, 53, 65)" }}
          >
            start journey
          </Link>
        </section>
      </main>
      <Footer />
    </>
  )
}
export const pageQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
        type
      }
    }
  }
`
